











import Vue from 'vue';
import { mapState } from 'vuex';


export default Vue.extend({

  computed: {

    videoFullUrl() {
      const isPaid = false;
      const server = isPaid ? 'vt' : 'n2w';
      return this.videoUrl ? `https://${server}.medialooks.com:8080/${this.videoUrl}` : '';
    },

    ...mapState('meeting', [
      'videoUrl'
    ])

  }

});
