



























import Vue from 'vue';
import { Store, mapGetters, mapState } from 'vuex';
import { StateRoot } from './types/store';
import Login from './components/Login.vue';
import NotConnected from './components/NotConnected.vue';
import Header from './components/Header.vue';
import ContentChooser from './components/ContentChooser.vue';
import RequestSpeak from './components/RequestSpeak.vue';
import Agenda from './components/Agenda.vue';
import SpeakerList from './components/SpeakerList.vue';
import VoteState from './components/VoteState.vue';
import Browser from './components/Browser.vue';
import VideoPlayer from './components/VideoPlayer.vue';
import Footer from './components/Footer.vue';

export default Vue.extend({
  name: 'App',

  components: {
    Login,
    NotConnected,
    Header,
    ContentChooser,
    RequestSpeak,
    Agenda,
    SpeakerList,
    VoteState,
    Browser,
    VideoPlayer,
    Footer
  },

  data() {
    return {
      storeUnsubscriber: () => {/*no-op*/},
      isSmall: false
    };
  },

  created() {
    this.$store.dispatch('message/connectionInit');
    // then do this after connection is done:
    const store = this.$store as Store<StateRoot>;
    this.storeSubscribeOnce('message/connectionInitDone', function cb() {
      store.dispatch('panel/loginRestore');
    }.bind(this));
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    this.storeUnsubscriber();
    this.storeUnsubscriber = () => {/*no-op*/};
    window.removeEventListener('resize', this.onResize);
  },

  computed: {

    ...mapState('panel', [
      'contentVisible'
    ]),

    ...mapGetters('panel', [
      'authenticated',
      'connected'
    ]),

  },

  methods: {

    onResize() {
      const isSmall = window.innerWidth <= 650;
      if (this.isSmall !== isSmall) {
        if (isSmall) {
          // it's now small
          if (this.contentVisible !== 'video') {
          this.$store.commit('panel/contentVisible', 'buttons');
          }
        } else {
          // it's now big
          if (this.contentVisible === 'buttons') {
            this.$store.commit('panel/contentVisible', 'browser');
          }
        }
      }
      this.isSmall = isSmall;
    },

    // wish I could subscribe to specific messages instead
    storeSubscribeOnce(actionType: string, done: () => void) {
      const store = this.$store as Store<StateRoot>;
      /*eslint-disable-next-line */
      const that = this;
      this.storeUnsubscriber = store.subscribeAction(function cb(action: {type: string}/*, state: StateRoot*/) {
        if (action.type === actionType) {
          done();
          that.storeUnsubscriber();
          that.storeUnsubscriber = () => {/*no-op*/};
        }
      }.bind(this));
    }

  }
});
