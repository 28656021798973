import { ActionContext, Module } from 'vuex';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { StateRoot, MessageState } from '../types/store';
import messageParse from '@/services/messageParse';
import { ConnectionInit, LoginDone } from '../types/panel';

const initState: MessageState = {
  connection: undefined,
  socketConnected: false,
  socketFail: false,
  signalrUrl: '/api', // TODO: pull from config
  // overwritten in moments by connectionInitDone:
  consoleLogMessages: process.env.NODE_ENV !== 'production'
};

async function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const messageStore: Module<MessageState, StateRoot> = {
  namespaced: true as const,
  state: initState,
  getters: {},
  actions: {
    async connectionInit(context: ActionContext<MessageState, StateRoot>): Promise<void> {

      const connection: HubConnection = new HubConnectionBuilder()
        .withUrl(context.state.signalrUrl)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Critical)
        .build();

      context.commit('connection', connection);

      connection.on('receiveMessage', (message: string) => {
        context.dispatch('receiveMessage', message);
        messageParse(message, context);
      });

      connection.on('connectionInit', (args: ConnectionInit) => {
        context.dispatch('connectionInitDone', args);
      });

      connection.onreconnecting(() => {
        context.commit('socketConnected', false);
      });

      connection.onreconnected(() => {
        context.commit('socketConnected', true);
      });

      connection.onclose(() => {
        context.dispatch('connectionRetry');
      });

      context.dispatch('connectionRetry');

    },

    async connectionRetry(context: ActionContext<MessageState, StateRoot>): Promise<void> {

      if (!context.state.connection) {
        // start instead
        context.dispatch('connectionInit');
        return;
      }

      let connectRetry = 0;

      async function reconnect() {
        const connection = context.state.connection;
        if (!connection) {
          return; // can't reconnect to nothing
        }
        if (context.state.socketConnected) {
          return; // already done
        }
        context.commit('socketFail', false);
        try {
          await connection.start();
          context.commit('socketConnected', true);
          await connection.send('connectionInit'); // ask if tcpConnected
          connectRetry = 0;
        } catch (err) {
          connectRetry++;
          if (connectRetry > 10) {
            console.error('giving up on socket, logging out');
            context.commit('socketFail', true);
            context.dispatch('panel/logout', undefined, {root: true});
            return;
          }
          if (context.state.consoleLogMessages) {
            console.log('error connecting socket, waiting ...');
          }
          await sleep(1000 * connectRetry);
          if (context.state.socketConnected) {
            return; // it connected while we were waiting
          }
          if (context.state.consoleLogMessages) {
            console.log('retrying socket connection ...');
          }
          reconnect(); // recurse
        }
      }

      reconnect(); // start off recursive process
    },

    async sendMessage(context: ActionContext<MessageState, StateRoot>, message: string): Promise<void> {
      const state = context.state;
      if (!state.socketConnected) {
        console.log('TCP not connected, logging out and not sending: ' + message);
        const loginfail: LoginDone = {
          authenticated: false
        };
        context.dispatch('panel/loginDone', loginfail, {root: true});
        return;
      }
      if (state.consoleLogMessages) {
        console.log('sending: ' + message);
      }
      await context.state.connection?.send('sendMessage', message);
    },

    connectionInitDone(context: ActionContext<MessageState, StateRoot>, args: ConnectionInit): void {
      context.commit('panel/tcpConnected', args.tcpConnected, {root: true});
      context.commit('consoleLogMessages', args.consoleLogMessages);
    },

    receiveMessage(context: ActionContext<MessageState, StateRoot>, message: string | undefined): void {
      if (context.state.consoleLogMessages) {
        console.log('receivedMessage', message);
      }
    },

    unhandledMessage(context: ActionContext<MessageState, StateRoot>, message: string | undefined): void {
      console.error('ignoring unhandled inbound message:', message);
    },

    otherUserMessage(context: ActionContext<MessageState, StateRoot>, message: string | undefined): void {
      if (context.state.consoleLogMessages) {
        console.log('ignoring message for another user:', message);
      }
    }

  },
  mutations: {

    connection(state: MessageState, connection: HubConnection): void {
      state.connection = connection;
    },
    socketConnected(state: MessageState, socketConnected: boolean): void {
      state.socketConnected = socketConnected;
    },
    socketFail(state: MessageState, socketFail: boolean): void {
      state.socketFail = socketFail;
    },
    consoleLogMessages(state: MessageState, consoleLogMessages: boolean): void {
      state.consoleLogMessages = consoleLogMessages;
    }

  }
};

export default messageStore;
